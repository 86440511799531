<template>
  <div class="User">
    <transition name="fade" mode="out-in">
      <alert v-if="alertOpen" :content="alertContent" @confirm="closeAlert" />
    </transition>

    <div class="panel">
      <div class="Grid-row">
        <div class="Grid-column-12">
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Created at</th>
                <th>Broadcast URL</th>
                <th>KPay Donate URL</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="broadcast in openBroadcasts" :key="broadcast.id">
                <td>{{ broadcast.title }}</td>
                <td>
                  {{ broadcast.created_at | moment('MMM DD, YYYY H:mm') }}
                </td>
                <td>{{ broadcast.broadcast_url }}</td>
                <td>{{ broadcast.kpay_donate_url }}</td>
                <td>
                  <button
                    class="button button-danger"
                    @click="closeBroadcast(broadcast)"
                  >
                    Close broadcast
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/common/navigation/Alert';

export default {
  name: 'OpenBroadcastsList',
  components: {
    Alert,
  },
  data() {
    return {
      alertOpen: false,
      alertContent: null,
    };
  },
  computed: {
    openBroadcasts: {
      get() {
        return this.$store.getters.openBroadcasts;
      },
    },
  },
  mounted() {
    this.$store.dispatch('getOpenBroadcasts');
  },
  methods: {
    closeAlert() {
      this.alertOpen = false;
    },
    closeBroadcast(broadcast) {
      this.$store
        .dispatch('closeBroadcast', { broadcast })
        .then(() => {
          this.alertContent = 'Broadcast has been closed.';
          this.alertOpen = true;
        })
        .catch(() => {
          this.alertContent =
            'There were errors processing request. Please try again.';
          this.alertOpen = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border: 2px solid $background-dark;
  border-radius: 3px;
  background-color: $white;
  margin: auto;
}

th {
  background-color: $brand-primary;
  color: $white;
  select: none;
}

td {
  background-color: $background-lightgray;
}

th,
td {
  min-width: 120px;
  padding: 10px 20px;
}
</style>
